import { Box, Button, InputAdornment, Menu, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import ProfileComplete from "./ProfileComplete"
import { Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { all_countries } from "../../utils/SetupDropdown"
import { AdviseIconLarge, ArrowHeadDown, CurrencySelectDropdownArrow } from "../../assets/SVG/svg"
import { Link, useNavigate } from "react-router-dom"
import { localStorageValueTester, ValueTester } from "../../utils/UtilityFunctions"
import { profile } from "../../services/api"
import ButtonSpinnerCustom from "../../components/common/Button/ButtonSpinnerCustom"
import { AuthContext } from "../../utils/AuthContext"

const QuickProfileSetup = () => {

    const [loading, setLoading] = useState(true)
    const [apiLoading, setApiLoading] = useState(false)
    const [setupCompleted, setSetupCompleted] = useState(false)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const { logout } = useContext(AuthContext);

    // mui drop down menu
    const [anchorEl, setAnchorEl] = useState()
    const [openMenu, setOpenMenu] = useState(false);
    const handleClick = (event) => {
        setOpenMenu(true);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false)
    };


    const [profileData, setProfileData] = useState({
        company_name: "",
        company_country: "",
        country_code: ""
    })


    const isdefault =
    // if has_profile/profile doesn't exist or is null at first run then is_default is set to true
    localStorageValueTester(localStorage.getItem("profile")) === false ||
    localStorage.getItem("profile") === "false"
      ? true
      : false;

    useEffect(()=>{
        if(!localStorage.getItem('token')){
            logout();
            navigate('/login');
        }
        else if (localStorageValueTester(localStorage.getItem("profile")) === true && localStorage.getItem("profile") === "true") {
            navigate("/")
        }        
        else{
            setLoading(false)
        }
    },[])

    const createProfile = async () => {

        setApiLoading(true)
        try{
            const response = await profile({
                token: `${localStorage.getItem("token")}`,
                value: {
                    "country": profileData?.company_country,
                    "name":  profileData?.company_name,
                    "legal_type": "",
                    "status": "",
                    "incorporation_year": null,
                    "industry": "",
                    "jurisdiction": "",
                    "taxable_income": 0,
                    "taxable_expenses": 0,
                    "monthly_txns": 0,
                    "registered": [],
                    "extra_info": [
                      {
                        "label": "Do you have branches?",
                        "labelArabic": "Do you have branches?",
                        "value": false
                      },
                      {
                        "label": "Are you part of a Tax group?",
                        "labelArabic": "Are you part of a Tax group?",
                        "value": false
                      },
                      {
                        "label": "Do you import goods from foreign vendor?",
                        "labelArabic": "Do you import goods from foreign vendor?",
                        "value": false
                      },
                      {
                        "label": "Do you import service from foreign vendor?",
                        "labelArabic": "Do you import service from foreign vendor?",
                        "value": false
                      },
                      {
                        "label": "Do you have cross border related party transactions?",
                        "labelArabic": "Do you have cross border related party transactions?",
                        "value": false
                      }
                    ],
                    "branches": false,
                    "tax_group": false,
                    "goods_vendor": false,
                    "service_vendor": false,
                    "cross_border_txns": false,
                    "is_default": true // will set default profile to this, other wise login will be stuck on user not having any profiles
                  }
        })
            setSetupCompleted(true)    
            localStorage.setItem("profile", "true");                      
        }
        catch(error){
            setApiLoading(false)
        }
        finally{
            setApiLoading(false)
        }

    }


    if  (loading) {
        return(<>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: "100vh", background: "#faf5e1", paddingBottom: "10%" }}>
            <Spinner />
        </div></>)
    }
    else{
    return(<>
        <Box sx={{
            width:"100%",
            padding: {
                xs: "50px 0px 0px 0px",
                sm: "50px 50px 0px 50px"
            },
            display:"flex",
            justifyContent:"center"
            }}>
            <Box sx={{
                width: {
                    xs:"90%",
                    sm:"100%"
                },
            }}>
                {/* <svg style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.simpla.ai/')} width="141" height="42" viewBox="0 0 141 42" fill="none">
                    <path d="M0.00107181 24.6167L4.81548 23.9834C5.45152 27.7435 8.13123 30.0997 12.6271 30.0997C16.4424 30.0997 19.0311 28.0612 19.0311 25.0264C19.0311 22.3989 17.1696 21.0846 14.6707 20.4048L8.90276 18.91C5.04191 17.8681 1.54412 15.7843 1.54412 10.7098C1.54412 5.36416 6.17758 1.78552 12.1265 1.78552C16.8043 1.78552 21.8463 4.05092 22.9365 9.80411L18.2585 10.5293C17.5315 7.67495 15.0338 6.18017 11.9455 6.18017C8.67526 6.18017 6.35854 7.99264 6.35854 10.4386C6.35854 12.8844 8.17571 14.0625 10.6734 14.7424L16.397 16.2372C20.3033 17.279 23.8911 19.6806 23.8911 24.8447C23.8911 30.4164 19.4397 34.4943 12.6271 34.4943C5.81451 34.4943 0.909081 31.0054 0 24.6178L0.00107181 24.6167Z" fill="#0D1E12" />
                    <path d="M33.4319 9.71118H29.026V33.9939H33.4319V9.71118Z" fill="#0D1E12" />
                    <path d="M31.2292 5.09281C32.6392 5.09281 33.7822 3.95269 33.7822 2.54637C33.7822 1.14004 32.6392 0 31.2292 0C29.8193 0 28.6763 1.14004 28.6763 2.54637C28.6763 3.95269 29.8193 5.09281 31.2292 5.09281Z" fill="#0D1E12" />
                    <path d="M50.0088 13.3818C46.9649 13.3818 44.0588 15.8731 44.0588 20.9475V33.9952H39.6528V9.71247H43.6047V14.0163C44.8314 11.2526 47.5111 9.21423 51.372 9.21423C55.2328 9.21423 57.5948 11.0267 58.9572 13.7904C60.4557 11.2083 63.3173 9.21423 67.269 9.21423C72.6747 9.21423 76.2625 13.0652 76.2625 19.3166V33.9952H71.8561V20.2234C71.8561 15.9639 69.6307 13.3829 66.1328 13.3829C63.0896 13.3829 60.1825 15.4213 60.1825 20.2234V33.9952H55.7768V20.2234C55.7768 15.4657 53.006 13.3829 50.0088 13.3829V13.3818Z" fill="#0D1E12" />
                    <path d="M93.8255 9.21423C86.3759 9.21423 81.0074 14.9512 81.0074 21.1388V41.923H85.4146V30.2782C87.4139 32.9511 90.5875 34.4923 94.4485 34.4923C101.172 34.4923 106.441 28.9186 106.441 21.8046C106.441 14.6907 101.254 9.21423 93.8255 9.21423ZM93.7223 30.369C88.9125 30.369 85.4146 26.744 85.4146 21.8046C85.4146 16.8653 88.9125 13.3829 93.7223 13.3829C98.5322 13.3829 102.036 17.0987 102.036 21.8046C102.036 26.5105 98.401 30.369 93.7223 30.369Z" fill="#0D1E12" />
                    <path d="M115.12 0.379395H110.714V33.995H115.12V0.379395Z" fill="#0D1E12" />
                    <path d="M140.594 33.9939V19.0927C140.594 13.293 136.006 9.21509 129.828 9.21509C124.018 9.21509 120.067 12.4327 119.522 17.4618L124.018 18.0054C124.155 14.9694 126.376 13.1115 129.828 13.1115C133.099 13.1115 135.506 15.3314 135.733 18.1859C134.552 18.548 132.735 18.9565 131.237 19.2278C125.291 20.2697 119.885 21.4478 119.885 27.4691C119.885 31.6377 123.065 34.4922 128.102 34.4922C131.782 34.4922 134.598 32.9066 136.279 29.916V33.9939H140.594ZM135.779 21.7602C135.779 26.9708 132.917 30.6412 128.692 30.6412C126.057 30.6412 124.518 29.2815 124.518 27.1978C124.518 24.5249 127.284 23.7543 131.191 23.029C133.054 22.7123 134.871 22.3492 135.779 21.7148V21.7602Z" fill="#0D1E12" />
                </svg>                 */}
                <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1.5%",
              alignItems: "center",
            }}
          >
              <svg style={{cursor:'pointer'}} onClick={()=>window.open('https://www.simpla.ai/')} xmlns="http://www.w3.org/2000/svg" width="141" height="42" viewBox="0 0 141 42" fill="none">
        <path d="M0.00107181 24.6167L4.81548 23.9834C5.45152 27.7435 8.13123 30.0997 12.6271 30.0997C16.4424 30.0997 19.0311 28.0612 19.0311 25.0264C19.0311 22.3989 17.1696 21.0846 14.6707 20.4048L8.90276 18.91C5.04191 17.8681 1.54412 15.7843 1.54412 10.7098C1.54412 5.36416 6.17758 1.78552 12.1265 1.78552C16.8043 1.78552 21.8463 4.05092 22.9365 9.80411L18.2585 10.5293C17.5315 7.67495 15.0338 6.18017 11.9455 6.18017C8.67526 6.18017 6.35854 7.99264 6.35854 10.4386C6.35854 12.8844 8.17571 14.0625 10.6734 14.7424L16.397 16.2372C20.3033 17.279 23.8911 19.6806 23.8911 24.8447C23.8911 30.4164 19.4397 34.4943 12.6271 34.4943C5.81451 34.4943 0.909081 31.0054 0 24.6178L0.00107181 24.6167Z" fill="#0D1E12"/>
        <path d="M33.4319 9.71118H29.026V33.9939H33.4319V9.71118Z" fill="#0D1E12"/>
        <path d="M31.2292 5.09281C32.6392 5.09281 33.7822 3.95269 33.7822 2.54637C33.7822 1.14004 32.6392 0 31.2292 0C29.8193 0 28.6763 1.14004 28.6763 2.54637C28.6763 3.95269 29.8193 5.09281 31.2292 5.09281Z" fill="#0D1E12"/>
        <path d="M50.0088 13.3818C46.9649 13.3818 44.0588 15.8731 44.0588 20.9475V33.9952H39.6528V9.71247H43.6047V14.0163C44.8314 11.2526 47.5111 9.21423 51.372 9.21423C55.2328 9.21423 57.5948 11.0267 58.9572 13.7904C60.4557 11.2083 63.3173 9.21423 67.269 9.21423C72.6747 9.21423 76.2625 13.0652 76.2625 19.3166V33.9952H71.8561V20.2234C71.8561 15.9639 69.6307 13.3829 66.1328 13.3829C63.0896 13.3829 60.1825 15.4213 60.1825 20.2234V33.9952H55.7768V20.2234C55.7768 15.4657 53.006 13.3829 50.0088 13.3829V13.3818Z" fill="#0D1E12"/>
        <path d="M93.8255 9.21423C86.3759 9.21423 81.0074 14.9512 81.0074 21.1388V41.923H85.4146V30.2782C87.4139 32.9511 90.5875 34.4923 94.4485 34.4923C101.172 34.4923 106.441 28.9186 106.441 21.8046C106.441 14.6907 101.254 9.21423 93.8255 9.21423ZM93.7223 30.369C88.9125 30.369 85.4146 26.744 85.4146 21.8046C85.4146 16.8653 88.9125 13.3829 93.7223 13.3829C98.5322 13.3829 102.036 17.0987 102.036 21.8046C102.036 26.5105 98.401 30.369 93.7223 30.369Z" fill="#0D1E12"/>
        <path d="M115.12 0.379395H110.714V33.995H115.12V0.379395Z" fill="#0D1E12"/>
        <path d="M140.594 33.9939V19.0927C140.594 13.293 136.006 9.21509 129.828 9.21509C124.018 9.21509 120.067 12.4327 119.522 17.4618L124.018 18.0054C124.155 14.9694 126.376 13.1115 129.828 13.1115C133.099 13.1115 135.506 15.3314 135.733 18.1859C134.552 18.548 132.735 18.9565 131.237 19.2278C125.291 20.2697 119.885 21.4478 119.885 27.4691C119.885 31.6377 123.065 34.4922 128.102 34.4922C131.782 34.4922 134.598 32.9066 136.279 29.916V33.9939H140.594ZM135.779 21.7602C135.779 26.9708 132.917 30.6412 128.692 30.6412C126.057 30.6412 124.518 29.2815 124.518 27.1978C124.518 24.5249 127.284 23.7543 131.191 23.029C133.054 22.7123 134.871 22.3492 135.779 21.7148V21.7602Z" fill="#0D1E12"/>
        </svg>

        <Box sx={{display:{xs:'block',md:'none'}}}> 

        <Button
        id="basic-button"
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
      >
                <svg id='menu-button' width={'32'} viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 12H20" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> <path d="M5 17H20" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> <path d="M5 7H20" stroke="#000000" strokeWidth="2" strokeLinecap="round"></path> </g></svg>                      

      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={()=>{navigate('/')}}> <Link style={{textTransform:"none",textDecoration: "none", color:'black', fontFamily: "Karla", fontSize:'20px'}}> {t("home")} </Link></MenuItem> */}
        <MenuItem onClick={()=>{window.open('https://www.simpla.ai/faqs/')}}> <Link style={{textTransform:"none",textDecoration: "none", color:window.location.href.includes('login') ?'#FF7420' : 'black', fontFamily: "Karla", fontSize:'20px'}}>{t("need_help")}</Link> </MenuItem>
        <MenuItem onClick={()=>{logout();
                  localStorage.removeItem('show-disclaimer');
                  localStorage.removeItem('firstname');
                  localStorage.removeItem('lastname');
                  navigate("/login");}}> 
                  <Link style={{textTransform:"none",textDecoration: "none", color:window.location.href.includes('signup') ?'#FF7420' : 'black', fontFamily: "Karla", fontSize:'20px'}}>{t("signout")}</Link> </MenuItem>
      </Menu>
        </Box>


            <Box sx={{ display: {xs:'none',md:'flex'}, gap:"3.5rem" }}>
              {/* <Typography
              onClick={()=>navigate('/')}
                variant="body2"
                sx={{ cursor:'pointer', fontFamily: "Karla", fontWeight: 500, fontSize: "20px",cursor:'pointer' }}
              >
                
                {t("home")}
              </Typography> */}
              <Typography
              onClick={()=>window.open('https://www.simpla.ai/faqs/')}
                variant="body2"
                sx={{  color:window.location.href.includes('login') ?'#FF7420' :'',cursor:'pointer',  fontFamily: "Karla", fontWeight: 500, fontSize: "20px" }}
              >
                {" "}
                {t("need_help")}{" "}
              </Typography>
              <Typography
              onClick={()=>{
                logout();
                  localStorage.removeItem('show-disclaimer');
                  localStorage.removeItem('firstname');
                  localStorage.removeItem('lastname');
                  navigate("/login");
              }}
                variant="body2"
                sx={{  color:window.location.href.includes('signup') ?'#FF7420' :'', cursor:'pointer', fontFamily: "Karla", fontWeight: 500, fontSize: "20px" }}
              >
                {" "}
                {t("signout")}{" "}
              </Typography>
            </Box>

          </Box>
            </Box>

        </Box>


        <Box className="centered-box" sx={{
            // height: { xs: "100%", md:"calc(100vh - 110px)"}, 
            // minHeight: {xs: "100vh", md: "calc(100vh - 110px)"}
            height:"100%",
            minHeight:"100vh",
            flexDirection:"column"
            }}>

            {
                setupCompleted ? <ProfileComplete/> : <>
                <Box sx={{width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <AdviseIconLarge/>
                </Box>

                <Box component="div" className="quick-setup-main-container">

                    <Box sx={{
                        width:"100%",
                        padding: {
                            xs: "30px",
                            sm: "80px"
                        } 
                        }}>

                    <Box sx={{
                        width:"100%", 
                        pb: "50px"
                    }}>
                        <h3 style={{textAlign:'left', justifyContent:"baseline", width:"100%", padding:"0px"}} className="thank-you-message">
                        {t("profile_setup")}
                        </h3>
                    </Box>

                    <Stack gap={2}>
                        <p className="profile-setup-body-text">{t("profile_setup_body1")}</p>
                        <p className="profile-setup-body-text">{t("profile_setup_body2")}</p>
                    </Stack>

                    <Stack gap={3} sx={{padding:"40px 0px 10px 0px"}}>

                    <Box sx={{width:"100%"}}>
                        <h3 style={{padding:"0px 0px", fontSize:"16px", width:"100%"}} className='auth-labels'>Company Name</h3>
                        <TextField
                            name="company_name"
                            placeholder=''
                            sx={{
                                width:"100%",
                                "& .MuiInputBase-root": {
                                    paddingRight: "10px", // Adjust padding to fit icon well
                                    height:"49px",
                                    border:"1px solid #E8E8E8",
                                    width:"100%",
                                    borderRadius:"10px"
                                },
                                }}
                            onChange={(event)=>{
                                setProfileData({...profileData, company_name: event.target.value})
                            }}
                            value={profileData?.company_name}
                            // error={!profileData?.company_name || !profileData?.company_name?.length}
                            // helperText={t("company_name_req")}
                        />                        
                    </Box>

                    <Box sx={{width:"100%"}}>
                        <h3 style={{padding:"0px 5px", fontSize:"16px", width:"100%"}} className='auth-labels'>Company Location</h3>
                        <Select
                            labelId="currency-select-label"
                            value={profileData?.company_country}
                            sx={{height:"49px", border:"1px solid #E8E8E8", width:"100%", borderRadius:"10px"}}
                            onChange={(event) => {
                                setProfileData({...profileData, country_code: all_countries?.find((item) => item?.value === event.target.value)?.code, company_country: event.target.value })
                            }}
                            startAdornment={
                            <InputAdornment position="start">
                                {
                                profileData?.country_code?.length > 0 &&
                                <img
                                className={`${localStorage.getItem("i18nextLng") === "ar" && "flags-ar"}`}
                                style={{ width: "25px", height: "25px" }}
                                src={`https://flagsapi.com/${profileData?.country_code}/flat/64.png`}
                                width="24"
                                alt=""
                                />
                                }

                            </InputAdornment>
                            }

                            IconComponent={ArrowHeadDown} // Use the custom icon
                            style={{ paddingRight: '15px' }} // Add padding to make room for the custom icon
                        
                        >
                            {
                                all_countries?.map((country)=>(
                                    <MenuItem 
                                        // onChange={(event) => {
                                        //     setProfileData({...profileData, country_code: country?.code, company_country: country?.value })
                                        // }}                                
                                        value={country?.value}>{country?.value}</MenuItem>
                                ))
                            }
                        </Select>                        
                    </Box>


                    </Stack>

                    <div style={{width:"100%", justifyContent:"baseline", padding:"0px"}} className="button-container">
                    <div style={{borderRadius:"10px", width:"100%"}} className="custom-button" onClick={()=>{
                        if( ValueTester(profileData?.company_name) && ValueTester(profileData?.company_country && !apiLoading) ){
                            createProfile()                           
                        }
                        }}>
                            { apiLoading ? <ButtonSpinnerCustom style={{width:"25px", height:"25px", marginTop:"-4px"}} /> : t("next")}
                    </div>
                </div>

                    </Box>

                </Box>                     
                </>

           
            }



        </Box>
    </>)
    }
}

export default QuickProfileSetup