import { Spinner } from "react-bootstrap"
import { SuccessCheckmark } from "../../assets/SVG/svg"
import { useTracking } from "react-tracking"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import "./QuickProfileSetup.css"

const ProfileComplete = () => {
    
    const {t} = useTranslation()
    const {trackEvent} = useTracking()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);

    if  (loading) {
        return(<>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: "100vh", background: "#faf5e1", paddingBottom: "10%" }}>
            <Spinner />
        </div></>)
    }
    else{
    return(<>

    <div className="success-parent-container">
    <div className="success-main-container">

      <div style={{width:"100%"}} className="success-container">
        <div className="success-icon">
            <SuccessCheckmark size="75" color="#fe6100" />
        </div>
        <div>
            <h3 className="thank-you-message">
            {t("profile_setup")} <br/> {t("complete")}
            </h3>
        </div>
      </div>

      <div style={{paddingTop:"30px"}}>
        <p className="plan-description" style={{textAlign:"center"}}>{t("get_started")}</p>
      </div>




      <div style={{paddingTop:"35px"}} className="button-container button-padding">
        <div className="custom-button" onClick={()=>{
            navigate("/")
            }}>
                {t("letsgo")}
        </div>
      </div>


    </div>        
    </div>




    </>)}
}

export default ProfileComplete